import React from "react";
import {
  DownArrowIcon,
  DownArrowIconLight,
  LightLogo,
  Location,
  Logo,
  ProfileIcon,
  RightMoveIcon
} from "./svg";
import { Link } from "react-router-dom";

export default function Header({ className }) {

  return (
    <nav
      className={`navbar navbar-expand-lg  perfect-nav ${className}`}
      aria-label="Eleventh navbar example"
    >
      <div className="d-flex align-items-center gap-2">
        <button
          className="navbar-toggler menu_button"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasDarkNavbar"
          aria-controls="offcanvasDarkNavbar"
          aria-label="Toggle navigation"
        >
          <span className="button_arrow" />
        </button>
        <a className="navbar-brand" href="/">
          {className == "light-nav" ? <LightLogo /> : <Logo />}
        </a>
      </div>

      <div className="d-flex align-items-center justify-content-between nav_menu">
        <div
          className="offcanvas align-na-side offcanvas-start"
          tabIndex={-1}
          id="offcanvasDarkNavbar"
          aria-labelledby="offcanvasDarkNavbarLabel"
        >
          <div className="offcanvas-header">
            <a className="navbar-brand" href="/">
              {/* {className == "light-nav" ? <Logo /> : <LightLogo />} */}
              <Logo />
            </a>
            <button
              type="button"
              className="btn-close btn-close-dark"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body">
            <ul className="navbar-nav nav_bar ms-0 ms-lg-4 me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="/#sellCar"
                  onClick={() => {                                    
                    setTimeout(() => {
                        const docs = document.getElementById('sellCar')
                        window?.scrollTo(0, docs?.offsetTop - 150);
                    }, 0);
                }}
                >
                  Sell My Car
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/value-tracker#work"
                 onClick={() => {                                    
                  setTimeout(() => {
                      const docs = document.getElementById('work')
                      window?.scrollTo(0, docs?.offsetTop - 150);
                  }, 0);
              }}
                >
                  How it works
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/contact-us">
                  Help
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link d-flex gap-1 align-items-center menu-toggler"                                   
                  href="javascript:void(0)"
                  data-bs-toggle="collapse" data-bs-target="#navbarToggleOne"
                >
                  Tools
                  {className == "light-nav" ? <DownArrowIcon /> : <DownArrowIconLight />}
                </a>
                <div className="nav-sub-menu collapse" id="navbarToggleOne">
                  <ul className="drop-menu sub_menu">
                    <li>
                      <a className="dropdown-item" href="/value-tracker">
                        Value Tracker
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link d-flex gap-1 align-items-center menu-toggler"                                                 
                  href="javascript:void(0);"  
                  data-bs-toggle="collapse" data-bs-target="#navbarToggleTwo"             
                >
                  More
                  {className == "light-nav" ? <DownArrowIcon /> : <DownArrowIconLight />}
                </a>
                <div className="nav-sub-menu collapse" id="navbarToggleTwo">
                  <ul className="drop-menu sub_menu">
                    <li>
                      <a className="dropdown-item" href="/about">
                        About us
                      </a>
                      <a className="dropdown-item" href="/guide">
                        Guides
                      </a>
                      <a className="dropdown-item" href="/true-story">
                        True stories
                      </a>
                      <a className="dropdown-item" href="/faq">
                        FAQ’s
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
          {/* <div className="d-flex gap-2 align-items-center set-p-loc me-2 user_store">
            <Location />
            <div className='store-location'>
            <span className='d-lg-block mb-1 d-none'>Your store for 92618</span>
            <select name="store" id="store" className='bg-transparent border-0 outline-0'>
              <option value="">Irvine</option>
              <option value="">Irvine</option>
              <option value="">Irvine</option>
            </select>
            </div>
            <div className="store-log-align">
              <p className={className === 'light-nav' ? "mb-0 store-text-size" : "color-dark mb-0 store-text-size"}>Your store for 92618</p>
              <div className="gp-align-menu">
                <p className={className === "light-nav" ? "color-light  fs-16 weight-600 line-16" : "fs-16 weight-600 line-16"}>Irvine</p>
                {className == "light-nav" ? <DownArrowIcon /> : <DownArrowIconLight />}
              </div>
            </div>
          </div> */}
        </div>

        <div
          className="set-justic-prof nav-item dropdown"

        >
          <div className={className === 'light-nav' ? "profile-hov-effect pointer" : "bg-yellow profile-hov-effect pointer"} data-bs-toggle="dropdown"
            aria-expanded="false">
            <ProfileIcon />
          </div>
          <p className="fs-16 line-16 weight-600 sing_in">Sign In</p>
          {
            localStorage.getItem('userid') ?
              <ul className="dropdown-menu set-right-box-drop">
                <li>
                  <p className='my-2'>{localStorage.getItem('full-name')}</p>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <Link className="dropdown-item" to="/login" onClick={() => localStorage.clear()}>
                    Sign out
                  </Link>
                </li>
              </ul>
              :
              <ul className="dropdown-menu set-right-box-drop">
                {/* <button className="make-btn-set mb-2">
                  <span>MAKE A PAYMENT</span>
                </button> */}
                <li>
                  <Link className="dropdown-item" to="/login">
                    Sign in
                  </Link>
                  <RightMoveIcon />

                </li>
                <li>
                  <Link className="dropdown-item" to="/register">
                    Register
                  </Link>
                  <RightMoveIcon />
                </li>
              </ul>
          }
        </div>
        {/* <div className='dropstart d-block'>
          {
            localStorage.getItem('userid') ?
              <div className="dropdown drop_menu text-end">
                <a
                  href="/"
                  className="d-block link-body-emphasis text-decoration-none  usertoggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="true"
                >
                  {className == 'light-nav' ?
                    <LightUser />
                    :
                    <User />
                  }
                </a>
                <ul
                  className="dropdown-menu text-small"
                  data-popper-placement="bottom-start"
                  style={{
                    position: "absolute",
                    inset: "0px auto auto 0px",
                    margin: 0,
                    transform: "translate(0px, 34px)"
                  }}
                >
                  <li>
                    <p className='px-3 my-2'>{localStorage.getItem('full-name')}</p>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/login" onClick={() => localStorage.clear()}>
                      Sign out
                    </Link>
                  </li>
                </ul>
              </div>
              :
              <div className='d-flex gap-2 header_button'>
                <button className='gray-btn border-0 btnyellow'  onClick={() => navigate('/login')}>
                  <span>
                    Login
                  </span>
                </button>
                <button className='gray-btn border-0 ' onClick={() => navigate('/register')}>
                  <span>
                    Register
                  </span>
                </button>
              </div>
          }
        </div> */}
      </div>
    </nav>
  );
}
