import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { DesignLeft, DesignRight, LinkIcon, YellowLineTwo } from '../components/svg'
import { Link } from 'react-router-dom'
import SectionWaiting from '../components/SectionWaiting'

export default function UserDetails() {
      return (
            <>
                  <div className='hero-section position-relative true-value-bg user-detail-bg'>
                        <div className='container'>
                              <Header className={"light-nav"} />
                              <div className='mt-100'>
                                    <div className='row justify-content-center mb-40'>
                                          <div className='col-12'>
                                                <div className='d-flex flex-column align-items-center gap-3'>
                                                      <span className='small-bas-heading color-yellow'>Your account</span>
                                                      <h1 className='main-heading color_white mb-0'>Welcome Back, Matthew</h1>
                                                      <p className='mb-0 color-light-gray opacity-75'>Answer a few questions about your vehicle and then connect.</p>
                                                </div>
                                          </div>
                                    </div>
                                    <div className='d-flex flex-column gap-3'>
                                          <div className='details_card'>
                                                <div className='d-flex flex-sm-row flex-column align items-center justify-content-between gap-sm-0 gap-4'>
                                                      <div className='d-flex flex-column justify-content-between'>
                                                            <p className='car_lable text_line position-relative mb-sm-0 mb-2'>Land Rover Range Rover <br /> Sport HSE DYN P400E A</p>
                                                            <p className='mb-0 color-border fw-semibold'>VIN No: <span className='color-purpal'>C7 JLT</span></p>
                                                      </div>
                                                      <div className='d-flex flex-column gap-sm-3 gap-2'>
                                                            <p className='car_lable mb-0 p-0 text_gray text-capitalize'>Estimated sale price</p>
                                                            <h2 className='car_prise mb-0'>$23,025</h2>
                                                      </div>
                                                </div>
                                                <div className='d-flex align-items-center flex-wrap gap-2 mt-20'>
                                                      <p className='mb-0 color-border fw-medium'>2019</p>
                                                      <span className='dote'></span>
                                                      <p className='mb-0 color-dark fw-medium'>25,000 Miles</p>
                                                      <span className='dote'></span>
                                                      <p className='mb-0 color-dark fw-medium'>Hybrind</p>
                                                      <span className='dote'></span>
                                                      <p className='mb-0 color-dark fw-medium'>Automatic</p>
                                                </div>
                                                <div className='d-flex flex-sm-row flex-column align-items-sm-center align-items-start car-value-details'>
                                                      <p className='mb-0 color-dark fw-medium dealer_add'>Free Home Collection</p>
                                                      <span className='h-border'></span>
                                                      <p className='mb-0 color-dark fw-medium dealer_add'>Fast Payment</p>
                                                      <span className='h-border'></span>
                                                      <p className='mb-0 color-dark fw-medium dealer_add'>No Fees to pay, ever</p>
                                                </div>
                                                <div className='d-flex gap-3 pt-1 flex-sm-nowrap flex-wrap align-items-center'>
                                                      <button className='gray-btn border-0 btn-dealer text-center btn_print w-auto'>
                                                            <span className='w-100'>Start your profile</span>
                                                      </button>
                                                      <div>
                                                            <p className='mb-0 heding-normal color-dark'>Track your value car</p>
                                                            <span className='heding-normal color-dark pointer opacity-75'>Check Now &#62;</span>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                                    <div className='text-center mt-20'>
                                          <button className='btn-bor-remove border-0 instant-brn vehicle-btn'  data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                <span>ADD ANOTHER VEHICLE</span>
                                          </button>
                                    </div>

                              </div>
                        </div>
                        <div className='icon-left'>
                              <DesignLeft/>
                        </div>
                        <div className='icon-right'>
                              <DesignRight/>
                        </div>
                  </div>
                  <div className='u_info deal-history padding-block-80'>
                        <div className='container'>
                              <div className='d_grid'>
                                    <div className='info-card yellow-card'>
                                          <h3 className='mb-0 sub-heading'>Personal Information</h3>
                                          <div className='d-flex flex-column gap_20 mt-40'>
                                                <div>
                                                      <label className='form-label opacity-75'>Full Name</label>
                                                      <div className='br-input-align w-100'>
                                                            <input type="text" className='form-control input-pt-set w-100' placeholder='Matthew Rodriguez' />
                                                      </div>
                                                </div>
                                                <div>
                                                      <label className='form-label opacity-75'>Email</label>
                                                      <div className='br-input-align'>
                                                            <input type="text" className='form-control input-pt-set w-100' placeholder='MattRodriguez@gmail.com' />
                                                      </div>
                                                </div>
                                                <div>
                                                      <label className='form-label opacity-75'>Phone no.</label>
                                                      <div className='br-input-align'>
                                                            <input type="text" className='form-control input-pt-set w-100' placeholder='+1  212 456 7890' />
                                                      </div>
                                                </div>
                                                <div>
                                                      <button className='gray-btn border-0 btn-dealer text-center btn_print w-auto'>
                                                            <span className='w-100'>save personal infromation</span>
                                                      </button>
                                                </div>
                                          </div>
                                    </div>
                                    <div className='info-card position-relative bg-purple d-flex flex-column justify-content-between gap-lg-0 gap-5'>
                                          <div>
                                                <h3 className='mb-0 big-middel-heading color_white'><span className='color-yellow'>Contact</span> Preferences</h3>
                                                <p className='mb-0 mt-10 color-light-gray opacity-75'>If you opt out, we may still contact you to assist you with your sale.</p>
                                          </div>
                                          <div className='d-flex flex-column gap_20'>
                                                <div className='info-card p-20 d-flex flex-column gap_30'>
                                                      <div className='d-flex align-items-center gap-1'>
                                                            <YellowLineTwo />
                                                            <p className='mb-0 color-dark fw-semibold text text-uppercase'>Marketing and services</p>
                                                      </div>
                                                      <div>
                                                            <p className='car_lable mb-0 p-0 text_gray fw-medium text-capitalize'>Choose your preferences for marketing and services communications.</p>
                                                            <Link className='color-purplr medium-heading' href="">Manage your email and SMS preferences <span className='ps-1'><LinkIcon /></span></Link>
                                                      </div>
                                                </div>
                                                <div className='info-card p-20 d-flex flex-column gap_30'>
                                                      <div className='d-flex align-items-center gap-1'>
                                                            <YellowLineTwo />
                                                            <p className='mb-0 color-dark fw-semibold text text-uppercase'>Car Value Tracker alerts</p>
                                                      </div>
                                                      <div>
                                                            <p className='car_lable mb-0 p-0 text_gray fw-medium text-capitalize'>Choose your preferences for monthly alerts about your vehicle's value.</p>
                                                            <Link className='color-purplr medium-heading' href="">Manage your valuation email alerts</Link>
                                                      </div>
                                                </div>
                                          </div>
                                          <div className='info-card-icon'>
                                                <img src="/assets/img/icons/card_shadow.svg" alt="icon" />
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
                  <SectionWaiting />
                  <Footer />

                        
                  <div className="modal fade modal-vehicle" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                              <div className="modal-content">                                   
                                    <div className="modal-body details_card position-relative">
                                          <button type="button" className="btn-close modal_close" data-bs-dismiss="modal" aria-label="Close" />                                        
                                          <h2 className='sub-heading'>Add Another Vehice</h2>
                                          <p className='car_lable text_line position-relative mb-0'>To start selling, or track the value of your vehicle, <span className='color-purpal'>enter the reg.</span></p>                                        
                                          <div className='br-input-align mt-30'>
                                                <input type="text" className='form-control input-pt-set text-center' placeholder='Enter REG'/>
                                          </div>
                                          <button className='gray-btn border-0 btn-dealer text-center btn_print w-100 mt-10'>
                                                <span className='w-100'>Continue</span>
                                          </button>
                                    </div>                                    
                              </div>
                        </div>
                  </div>
            </>
      )
}
