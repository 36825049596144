import React, { useState } from 'react';
import { YellowLine } from '../svg';
import axios from 'axios';
import { ROOT_URL } from '../../utils/rootUrl';
import { MessageBox } from '../MessageBox';
import { isPossiblePhoneNumber } from 'libphonenumber-js';
import { useNavigate } from 'react-router-dom';

export default function StepOffer({ inputs, validations, setValidations, handleInputChange, setCurrentStep, setCarValuation, setCarId }) {

  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();

  const checkValidations = () => {
    if (loading) return
    if (!inputs.first_name) {
      setValidations(prev => ({ ...prev, first_name: true }))
      return
    }
    if (!inputs.last_name) {
      setValidations(prev => ({ ...prev, last_name: true }))
      return
    }
    if (!/^([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+)*)@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z](?:[a-zA-Z0-9-]*[a-zA-Z])?$/.test(inputs.email)) {
      setValidations(prev => ({ ...prev, email: true }))
      return
    }
    if (!isPossiblePhoneNumber(String(inputs.phone_number), 'US')) {
      setValidations(prev => ({ ...prev, phone_number: true }))
      return
    }
    getCarValuation()
  }

  const getCarValuation = async () => {
    // if (inputs.condition?.[0]?.ansId == 2) {
    //   setCurrentStep(prev => (prev + 1))
    //   return
    // }

    const params = {};
    for (const key in inputs) {
      if (key === "features" || key === "condition") {
        params[key] = JSON.stringify(inputs[key]);
      } else {
        params[key] = inputs[key];
      }
    }

    params.style = inputs.ucgsubsegment
    params.drive = inputs.drivetype
    params.fuel_type = inputs.fueltype
    params.vin_number = inputs.vin_number || inputs.lic_number

    try {
      setLoading(true)
      const res = await axios.post(`${ROOT_URL}/valuationForm`, params);
      setCarValuation(res.data?.data?.carValue || 0);
      setCarId(res.data?.data?.carID || undefined);
      navigate('/true-value', { state: inputs })
      // setCurrentStep(prev => (prev + 1))
    } catch (err) {
      console.log(err);
      MessageBox("error", err?.response?.data?.msg || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2 className='sub-heading mt-40 mb-0'>
        Offer delivery
      </h2>
      <div className='car-functionality mt-30'>
        <YellowLine />
        <p className='mb-0 ms-1 text-uppercase d-inline'>
          Please provide an email address and we'll send a copy of your <span className='color-purplr'>offer.</span>
        </p>
      </div>
      <div className="row mt-30 g-4">
        <div className="col-md-6 col-12 mt-0">
          <label className="form-label">First Name</label>
          <input
            type="text"
            className="form-control"
            placeholder="Jon"
            name='first_name'
            value={inputs.first_name}
            onChange={handleInputChange}
          />
          {validations.first_name && <div className='err_msg'>Please enter first name</div>}
        </div>
        <div className="col-md-6 col-12 mt-md-0 ">
          <label className="form-label">Last Name</label>
          <input
            type="text"
            className="form-control"
            placeholder="joh"
            name='last_name'
            value={inputs.last_name}
            onChange={handleInputChange}
          />
          {validations.last_name && <div className='err_msg'>Please enter last name</div>}
        </div>
        <div className="col-md-6 col-12">
          <label className="form-label">Email</label>
          <input
            type="text"
            className="form-control"
            placeholder="abc@gmail.com"
            name='email'
            value={inputs.email}
            onChange={handleInputChange}
          />
          {validations.email && <div className='err_msg'>{inputs.email ? 'Invalid email' : 'Please enter email'}</div>}
        </div>
        <div className="col-md-6 col-12">
          <label className="form-label">Phone no.</label>
          <input
            type="text"
            className="form-control"
            placeholder="+12 00000000"
            maxLength={17}
            name='phone_number'
            value={inputs.phone_number}
            onChange={handleInputChange}
          />
          {validations.phone_number && <div className='err_msg'>{inputs.phone_number ? 'Invalid USA phone number' : 'Please enter phone number'}</div>}
        </div>
        <div className="col-12">
          <label className="form-label">Other Information</label>
          <input
            type="text"
            className="form-control"
            placeholder=""
            name='other_info'
            value={inputs.other_info}
            onChange={handleInputChange}
          />
        </div>
        <div className="col-md-12 mt-40">
          <button className='gray-btn border-0 btnyellow' onClick={checkValidations}>
            <span>{loading ? 'Loading...' : 'Get you Offer'}</span>
          </button>
        </div>
      </div>
    </div>
  )
}